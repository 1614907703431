<!-- /*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/   -->
<fury-page-layout *ngIf="componentName !='uploadWorkflow'" mode="card" class="input-card">
  <fury-page-layout-header class="test" *ngIf="!isNotShowBreadCrum">
    <fury-breadcrumbs class="mcustomheight" [@fadeInRight] [crumbs]="[moduleName]" [isShow]="isShow"
      [componentName]="componentName" [cardName]="[card]" [crumbNew]="[pathName]" current="{{headertitle|translate}}">
    </fury-breadcrumbs>
  </fury-page-layout-header>

  <fury-page-layout-content [@fadeInUp] class="trade-view-input input-upload-header">

    <div fxLayout="column" fxLayoutAlign="start center">
      <div [@fadeInUp] class="card trade-view-card" id="formstiky">

        <mat-vertical-stepper #verticalStepper="matVerticalStepper" #stepper>


          <mat-step>
            <form>

              <ng-template class="trade-view-header" matStepLabel *ngIf="!isNotHeading">{{uploadTitleName|translate}}</ng-template>

              <!-- <div class="content input-trade-upload input-space-upload-slttemplate" fxLayout="column" fxLayoutGap="8px">
                
              </div> -->

              <div class="content input-trade-upload input-space-upload" fxLayout="column" fxLayoutGap="8px">
                <div class="exchange-rateupload">
                  <mat-form-field appearance="outline" *ngIf="showSmType">
                    <mat-label appRemoveAriaOwns class="label-font">{{'sm_type'|translate}} <sup style="color:red;">*</sup></mat-label>
                    <mat-select [(ngModel)]="smtype" [ngModelOptions]="{standalone: true}" [placeholder]="Client"
                      (selectionChange)="setOnChange(smtype)">
                      <mat-option>
                        <ngx-mat-select-search [formControl]="uploadFilterCtrl"></ngx-mat-select-search>
                        <ngx-mat-select-search [formControl]="uploadFilterCtrl"
                          placeholderLabel="{{'Search'|translate}}" [noEntriesFoundLabel]=' "No Results match "'>
                        </ngx-mat-select-search>
                      </mat-option>
                      <div class="matselectn" *ngFor="let element of filteredSMTypes | async">
                        <mat-option [value]="element.value">
                          {{element.name}}

                        </mat-option>
                        <span class="customtooltip">{{element?.name|translate}}</span>
                      </div>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field appearance="outline" *ngIf="operationType">
                    <mat-label appRemoveAriaOwns class="label-font">{{'upload_operation_type'|translate}} <sup style="color:red;">*</sup>
                    </mat-label>
                    <mat-select [(ngModel)]="opType" [ngModelOptions]="{standalone: true}" [placeholder]="Client"
                      class="form-control">
                      <div class="matselectn" *ngFor="let element of operationTypes">
                        <mat-option [value]="element.value">
                          {{element.name|translate}}

                        </mat-option>
                        <span class="customtooltip">{{element?.name|translate}}</span>
                      </div>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field appearance="outline" *ngIf="showProductType">
                    <mat-label appRemoveAriaOwns class="label-font">{{'Select Product Mapping Type'|translate}}</mat-label>
                    <mat-select appendTo="body" [(ngModel)]="productType" [ngModelOptions]="{standalone: true}" (selectionChange)="setOnChangeproduct(productType)"
                      placeholder="Select Product Mapping" class="form-control"
                      >
                      <div class="matselectn" *ngFor="let element of productTypes">
                        <mat-option [value]="element.value">
                          {{element.name|translate}}
  
                        </mat-option>
                        <span class="customtooltip">{{element?.name|translate}}</span>
                      </div>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                    <mat-label appRemoveAriaOwns class="label-font">{{'Select_template'|translate}}</mat-label>
                    <mat-select appendTo="body" [(ngModel)]="selectedOption" [ngModelOptions]="{standalone: true}"
                      placeholder="Select Client" class="form-control" disableOptionCentering
                      (selectionChange)="selectCustomType(selectedOption)">
                      <div class="matselectn" *ngFor=" let typeName of domainCustomTypes">
                        <mat-option [value]="typeName.name">
                          {{typeName.name|translate}}

                        </mat-option>
                        <span class="customtooltip">{{typeName?.name|translate}}</span>
                      </div>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field appearance="outline" *ngIf="showSelectClient" class="inputlft-spacecient">
                    <mat-label appRemoveAriaOwns class="label-font">{{'Select Client'|translate}}</mat-label>
                    <mat-select appendTo="body" [(ngModel)]="value" [ngModelOptions]="{standalone: true}"
                      placeholder="Select Client" [compareWith]="compareCategoryObjects" class="form-control"
                      disableOptionCentering>
                      <div class="matselectn" *ngFor="let element of prop">
                        <mat-option [value]="element.name">
                          {{element.name|translate}}

                        </mat-option>
                        <span class="customtooltip">{{element?.name|translate}}</span>
                      </div>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="outline" *ngIf="isShowThirdParySource" class="inputlft-spacecient">
                    <mat-label appRemoveAriaOwns class="label-font">{{'Select Client'|translate}}</mat-label>
                    <mat-select appendTo="body" [(ngModel)]="value" [ngModelOptions]="{standalone: true}"
                      placeholder="Select Client" [compareWith]="compareCategoryObjects" class="form-control"
                      disableOptionCentering>
                      <div class="matselectn" *ngFor="let element of thirdParySource">
                        <mat-option [value]="element.name">
                          {{element.name|translate}}

                        </mat-option>
                        <span class="customtooltip">{{element?.name|translate}}</span>
                      </div>
                    </mat-select>
                  </mat-form-field>




                  <div appearance="legacy" *ngIf="isCarryWeekend" class="carry_weekendbox">
                    <mat-checkbox [(ngModel)]="carryWeekend" [type]="checkbox" name="carryWeekend" [binary]="true">
                    </mat-checkbox>
                    <mat-label appRemoveAriaOwns style="margin-bottom: 15px;" class="label-font"> {{'carry_weekend'|translate}}
                    </mat-label>

                </div>
              </div>

                <div class="browse_section inputupload-brose-section"
                  *ngIf="componentName!='tradeUploadHV' && componentName!='rapidTradeUploadHV'">

                  <p-fileUpload class="customUpload" #fileUpload mode="advanced" [showCancelButton]="false"
                    [showUploadButton]="false" chooseLabel="{{'browse_file'|translate}}" customUpload="true"
                    (onBeforeUpload)="clear()" (onSelect)="onRead($event)" dragDropSupport="true">
                    <ng-template pTemplate="content">
                      <!-- <input type="file" id="myFile" name="filename">
                      <img src="assets/img/input-upload-icon.png"> -->
                      
                      <label for="myFile">
                          <img src="assets/img/input-upload-icon.png">
                        <h3 style="text-align: center;">
                          {{'choose_file_drag_here'|translate}} <br>
                          {{'or'|translate}}</h3> 
                          <!-- <input type="file" name="filename" id="myFile myfileupload"  class="fileupload-ui"> -->
                          <span class="upload-filename">{{filename?.name}}</span>
                          <!-- <p-button class="closeBtn" class="closex" *ngIf="uploadedData && uploadedData.length" type="button"
                          [label]="cancelLabel" icon="pi pi-times" (click)="clear()"></p-button> -->
                        </label>                  
                    </ng-template>
                  </p-fileUpload>

                </div>

                <div class="browse_section inputupload-brose-section borwse-btn-index"
                  *ngIf="componentName=='tradeUploadHV'||componentName=='rapidTradeUploadHV'">

                  <!-- <input  [id]="file" [type]="'file'" class="form-control "
                    style="padding-top: 0px; padding-bottom: 1em;"
                    (change)="onFileSelected($event)" > -->
                  <!-- <p-button class="closeBtn" class="closex" *ngIf="uploadedData && uploadedData.length" type="button"
                    [label]="cancelLabel" icon="pi pi-times" (click)="clear()"></p-button> -->
                  <p-fileUpload [id]="file" [type]="'file'" #fileUpload class="customUpload" [showCancelButton]="false"
                    [showUploadButton]="false" chooseLabel="{{'browse_file'|translate}}" customUpload="true"
                    (onBeforeUpload)="clear()" (onSelect)="onFileSelected($event)" dragDropSupport="true">
                    <ng-template pTemplate="content">                      
                      <label for="myFile">
                          <img src="assets/img/input-upload-icon.png">
                        <h3 style="text-align: center;" >
                          {{'choose_file_drag_here'|translate}} <br>
                          {{'or'|translate}}</h3> 
                          <span class="upload-filename">{{filename?.name}}</span>
                          </label>
                    </ng-template>
                  </p-fileUpload>

                </div>


                <div class="actions oms-list-btns button-view" fxLayout="row" fxLayoutAlign="end center"
                  fxLayoutGap="8px" style=" align-items: flex-end;"
                  *ngIf="componentName !='tradeUploadHV' && componentName!='rapidTradeUploadHV'">

                  <button *ngIf="isTemplateBtn" class="outline_btn" (click)="downloadSampleFile()">
                    {{'download_template'|translate}}
                  </button>
                  <!-- <button *ngIf="isTemplateBtn && sampleName !='allsmupload'" class="outline_btn"
                    (click)="fetchAllCustomTypes()">
                    {{'show_custom_list'|translate}}
                  </button> -->
                  <button *ngIf="isSubmitBtn" [disabled]="formValid" (click)="upload()" color="primary"
                    mat-raised-button>
                    {{'upload'|translate}}
                  </button>
                </div>
                <div class="actions button-view" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px"
                  style=" align-items: flex-end;"
                  *ngIf="componentName =='tradeUploadHV'||componentName=='rapidTradeUploadHV'">

                  <button *ngIf="isTemplateBtn" class="outline_btn" (click)="downloadSampleFile()">
                    {{'download_template'|translate}}
                  </button>

                  <button *ngIf="isSubmitBtn" [disabled]="formValid" (click)="uploadZipFile()" color="primary"
                    mat-raised-button>
                    {{'upload'|translate}}
                  </button>
                </div>
              </div>
            </form>
          </mat-step>

        </mat-vertical-stepper>
      </div>
    </div>





  </fury-page-layout-content>
</fury-page-layout>
<!-- <p-dialog header="{{'select_custom_upload_type'|translate}}" class="Dialog_width_Small" [(visible)]="displayBasic"
  [modal]="true">
  <div>
    <ul class="list-group box-shadow-n mat-divider">


      <li class="list-group-item" *ngFor=" let typeName of domainCustomTypes;let i = index" style="cursor: pointer"
        (click)="selectCustomType(typeName)">

        {{typeName|translate}}

      </li>


    </ul>
  </div>
</p-dialog> -->

<p-dialog header="{{'select_custom_upload_type'|translate}}" class="upload_dialog" [(visible)]="displayNoCustom"
  [modal]="true">
  <div>
    <ul class="list-group box-shadow-n mat-divider">


      <li class="list-group-item" style="cursor: pointer">{{'no_custom_type_exists'|translate}}</li>

    </ul>
  </div>
</p-dialog>

<p-dialog header="{{'select_preview_upload_in_tradeentry'|translate}}" class="upload_dialog"
  [(visible)]="selectPreviewAndAggregation" [modal]="true">
  <div>
    <ul class="list-group box-shadow-n mat-divider button-mat-divider">
      <li class="list-group-item" style="cursor: pointer">
        <p-button label="{{'preview_before_upload_tradeentry'|translate}}" class="p-button-text"
          [routerLink]="['/input/view/trade-sucess-list-hv']">
        </p-button>
      </li>
      <!-- <li  class="list-group-item" style="cursor: pointer"> <p-button  label="{{'direct_upload_tradeentry'|translate}}" (click)="selectPreviewAndAggregation=false"></p-button> -->
      <li *ngIf="failureListCount == 0" class="list-group-item" style="cursor: pointer">
        <p-button label="{{'direct_upload_tradeentry'|translate}}" [disabled]="isDoubleClick"
          (click)="directUploadTradeEntry()"></p-button>
      </li>
    </ul>
  </div>
</p-dialog>


<p-dialog header="{{'select_custom_upload_type'|translate}}" class="Dialog_width_Small" appendTo="body"
  [(visible)]="displayBasic2" [modal]="true" (onHide)="dialogClose($event)">
  <div class="browse_section" *ngIf="componentName =='uploadWorkflow'">
    <p-button class="closeBtn" class="closex" *ngIf="uploadedData && uploadedData.length" type="button"
      [label]="cancelLabel" icon="pi pi-times" (click)="clear()"></p-button>
    <p-fileUpload class="customUpload" #fileUpload mode="advanced" [showCancelButton]="false" [showUploadButton]="false"
      chooseLabel="{{'browse'|translate}}" customUpload="true" (onBeforeUpload)="clear()" (onSelect)="onRead($event)"
      dragDropSupport="true">
      <ng-template pTemplate="content">
        <h3 style="text-align: center;" *ngIf="dragDrop==true || (!uploadedData || uploadedData.length <0)">
          {{'drag_drop_file'|translate}}</h3>
      </ng-template>
    </p-fileUpload>
  </div>
  <p-footer>
    <button *ngIf="isSubmitBtn" class="upbtnrigh" [disabled]="formValid" (click)="upload()" color="primary"
      style="margin-top: 0.3em;float: right;" mat-raised-button>
      {{'submit'|translate}}
    </button>
  </p-footer>
</p-dialog>
