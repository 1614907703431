/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/

import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { DynamicFormComponent } from 'src/app/shared/dynamic-form/dynamic-form.component';
import moment from 'moment';
import { GlobalMessageService } from 'src/app/shared/services/commonServices/global-message.service';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { GridService } from 'src/app/shared/services/gridServices/grid.service';
import { FsScheduleCardsService } from '../fs-config-entry/fs-schedule-cards/fs-schedule-cards.service';
import { FinanceStatementService } from '../../finance-statement.service';

@Component({
  selector: 'fury-fs-duplicate-config',
  templateUrl: './fs-duplicate-config.component.html',
  styleUrls: ['./fs-duplicate-config.component.scss'],
  providers: [FsScheduleCardsService]
})
export class duplicateConfigComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<duplicateConfigComponent>,
    private gridService: GridService,
    private fsScheduleCardsService: FsScheduleCardsService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    public translate: TranslateService,
    private messageService: GlobalMessageService,
    private http: HttpClient,
    private financeStatementService: FinanceStatementService
  ) {}

  public heading: String = "Create Financial statement";

  public display: boolean = false
  public fundTypeList = [];
  public fundList = []
  public configNameList = []
  public reportStartDate: any;
  public reportEndDate: any;
  public basicDetailData: any = []
  @ViewChild(DynamicFormComponent) formEl;
  public formData = {};
  public formValid: boolean = false;
  public rowData: any = {}
  public rowDataSchedule: any = {}
  public prevConfigId: any;
  public fundListType: any = [
    { label: "Fund", value: "fund" },
    { label: "Fund Group", value: "fundGroup" }
  ];
  ngOnInit(): void {
    this.reportStartDate = moment(this.data.startDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
    this.reportEndDate = moment(this.data.endDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
    this.rowData = this.data
    this.rowDataSchedule = this.data.scheduleData
    this.prevConfigId = this.data.configId
    this.getformCOntrol()
  }

  basicDetails() {
    return [
      { id: 'fundListType', type: [], controlType: 14, label: '', viewname: 'fundListType', name: 'fundListType', order: 9, value: this.fundListType[0].value, required: false, fill: this.fundListType, class: "col-lg-12 ml-3 tiered-radio", style: 'display: block' },
      { id: 'fundIdList', type: [], controlType: 7, label: 'fund', viewname: 'fundIdList', name: 'fundIdList', order: 1, value: '', required: false, appearance: "fill", fill: this.fundList, readonly: false, class: "col-lg-12  ml-3 dropdown-bgcolors pt-0", style: 'display: none' },
      { id: 'fundGroupName', type: [], controlType: 1, label: 'fund_group_name', viewname: 'fundGroupName', name: 'fundGroupName', order: 1, value: '', required: false, readonly: false, disabled: false, class: "col-lg-12", style: 'display: none' },
      { id: 'fundId', type: [], controlType: 2, label: 'fund', viewname: 'fundId', name: 'fundId', order: 1, value: { id: this.rowData.fundId }, required: true, appearance: "fill", fill: this.fundList, disabled: true, readonly: false, class: "col-lg-12 mb-c", style: 'display: none' },
      { id: 'startDate', type: [], controlType: 3, label: 'start_date', viewname: 'startDate', name: 'startDate', order: 3, value: this.reportStartDate, required: true, appearance: "fill", class: "col-lg-6 mb-c" },
      { id: 'endDate', type: ['Private Equity Fund', 'Feeder Fund', 'Hedge Fund', 'Crypto Fund', 'Venture Capital Fund', 'Special Purpose Vehicle Fund', 'Mutual Fund'], controlType: 3, label: 'end_date', viewname: 'endDate', name: 'endDate', order: 3, value: this.reportEndDate, required: true, appearance: "fill", class: "col-lg-6 mb-c" },

    ]
  }

  formValidation(event: boolean) {
    this.formValid = this.formEl.form.status === "INVALID" ? true : false;
  }

  getformCOntrol() {
    this.fetchAllByEnterprise().subscribe((response) => {
      let fund = response.resultList && response.resultList.length ? response.resultList : [];
      this.fundList = fund.map(element => ({ label: element.fundAlias != null ? element.fundAlias : element.fundName, value: { "id": element.id } }));
      this.basicDetailData = this.basicDetails()

      if (this.rowData['fundGroupName']) {
        this.financeStatementService.updateField(this.basicDetailData, 'fundGroupName', {
          value: this.rowData['fundGroupName'],
          disabled: true,
          style: "display:block;"
        });
        this.financeStatementService.updateField(this.basicDetailData, 'fundListType', {
          value: 'fundGroup',
          disabled: true,
          style: "display:block;"
        });
      } else {
        this.financeStatementService.updateField(this.basicDetailData, 'fundId', {
          value: { id: this.rowData.fundId },
          disabled: true,
          style: "display:block;"
        });
        this.financeStatementService.updateField(this.basicDetailData, 'fundListType', {
          value: 'fund',
          disabled: true,
          style: "display:block;"
        });
      }

    })
  }

  OpenduplicatePopup() {
    this.getformCOntrol()
    this.display = true
  }
  setOnChange(evt) {
  }
  dialogClose() {
    this.formEl.form.reset();
    this.dialogRef.close();
  }


  submit() {
    Object.assign(this.formData, this.formEl.form.value);
    let startDate = moment(this.formData['startDate'], 'YYYY-MM-DD').format('YYYY-MM-DD')
    let endDate = moment(this.formData['endDate'], 'YYYY-MM-DD').format('YYYY-MM-DD');
    if (endDate < startDate) {
      this.messageService.add({ sticky: true, severity: 'error', summary: this.translate.instant('error'), detail: this.translate.instant('end_date_should_be_greater_than_start_date') });
      return;
    }
    this.formValid = true
    let req = this.rowData
    req['startDate'] = moment(this.formData['startDate']).format('YYYY-MM-DD');
    req['endDate'] = moment(this.formData['endDate']).format('YYYY-MM-DD');
    req['assetClass'] = null;
    req['subsequentEventsPeriodFrom'] = null;
    req['subsequentEventsPeriodThrough'] = null;
    req['sooGroup'] = null;
    req['expenseBorneByGP'] = null;
    req['affiliatedMembersCapital'] = null;
    req['inKindContributions'] = null;
    req['cashLimit'] = null;
    req['sidePocket'] = null;
    req['stakingIncome'] = null;
    req['stakedAssets'] = null;
    // req['notesToAccount'] = null;
  
    const keysToDelete = [
      'id', 'configId', 'scheduleData', 'username', 'configName',
      'updatedBy', 'updatedOn', 'insertedBy', 'insertedOn',
      'CustaccountNumber', 'assetClass', 'cust_Tag', 'executionLocked',
      'finalSequence', 'fundWorkflowSetup', 'inputMode',
      'isPreviewData', 'isWhiteList', 'uploadData'
    ];
    keysToDelete.forEach(key => delete req[key]);
    if (req['cellStyleComponent']) {
      delete req['cellStyleComponent']['_id'];
    }

    req['locked'] = false;
    req['duplicate'] = true;
    req['configId'] = this.prevConfigId;
    
    if (req && req['fundGroupName']) {
      req['fundId'] = null
      req['fundName'] = null
      req['fundIdList'] = req['fundIdList']
      req['fundGroupName'] = req['fundGroupName']
      // req['duplicate'] = false;
    }
    this.validateConfig(req).subscribe({
      next: (response) => {
        if (response && !response.success) {
          this.messageService.add({
            closable: true, sticky: true, severity: 'error', summary: this.translate.instant('failed'),
            detail: this.translate.instant(response.message)
          });
          this.formValid = false
          return;
        }
        if (response && response.data) {
          let setscheduleConfigReq = this.rowDataSchedule || {}
          setscheduleConfigReq['configId'] = response.data.configId
          setscheduleConfigReq['fundId'] = response.data['fundId']
          delete setscheduleConfigReq['id']
          if (req && req['fundGroupName']) {
            setscheduleConfigReq['fundId'] = null;
            setscheduleConfigReq['fundName'] = null;
            setscheduleConfigReq['fundGroupName'] = req['fundGroupName'];
          }
          this.setscheduleConfig(setscheduleConfigReq).subscribe(
            res => this.handleScheduleResponse(res, response.data, req),
            () => this.showErrorMessage('Failed to create schedule')
          );
          this.configurationFile(response.data, req);
        } else {
          this.messageService.add({
            closable: true, sticky: true, severity: 'warn', summary: this.translate.instant('warning'),
            detail: this.translate.instant('fund_configuration_already_exist')
          });
          this.formValid = false
        }
      }, error: (error) => {
        this.formValid = false
        this.messageService.add({
          closable: true, sticky: true, severity: 'error', summary: this.translate.instant('error'),
          detail: this.translate.instant('record_creation_failed')
        });
      }
    })
  }
  
  validateConfig(data): Observable<any> {
    if (data['fundGroupName']) {
      data['fundId'] = null,
      data['fundGroupName'] = data['fundGroupName'],
      data['fundIdList'] = data['fundIdList']
    }
    return this.http.post<any>('fSConfig/create', data);
  }
  private handleScheduleResponse(res: any, data: any, req: any): void {
    if (res && res.data) {
      this.copyChartOfAccounts(data, req);
    } else {
      this.showErrorMessage('Failed to create schedule');
    }
  }

  private copyChartOfAccounts(data: any, req: any): void {
    const chartOfAccountsObj = this.prepareChartOfAccountsRequest(data, req);
    this.fsScheduleCardsService.copyChartOfAccountsConfig(chartOfAccountsObj).subscribe(
      res => res && this.copyFSPosition(data, req),
      () => this.showErrorMessage('Failed to copy Chart of Accounts')
    );
  }
  private copyFSPosition(data: any, req: any): void {
    const positionObj = this.prepareFSPositionRequest(data, req);
    this.fsScheduleCardsService.copyFSPositionConfig(positionObj).subscribe(
      res => res && this.refreshFsExposure(data, req),
      () => this.showErrorMessage('Failed to copy FS Position')
    );
  }
  private refreshFsExposure(data: any, req: any): void {
    const exposureObj = this.prepareFsExposureRequest(data, req);
    this.fsScheduleCardsService.refreshFsExposure(exposureObj).subscribe(
      res => res && this.copyCashEquivalent(data, req),
      () => this.showErrorMessage('Failed to refresh FS Exposure')
    );
  }
  private updateCashFlow(data: any, req: any): void {
    const cashFlowObj = this.prepareCashFlowRequest(data, req);
    this.cashFlowUpdate(cashFlowObj).subscribe(
      () => this.onSuccessfulCreation(),
      () => this.showErrorMessage('Failed to update cash flow')
    );
  }

  private copyCashEquivalent(data: any, req: any): void {
    const cashEquivalentObj = this.prepareFScashAndCashEquivalentRequest(data, req);
    this.fsScheduleCardsService.refreshFsCashAndCashEquivalent(cashEquivalentObj).subscribe(
      res => res && this.updateCashFlow(data, req),
      () => this.showErrorMessage('Failed to copy FS Cash And Cash Equivalent')
    );
  }

  private configurationFile(data: any, req: any): void {
    const configObj = this.configurationFileRequest(data, req);
    this.copyConfigurationFile(configObj).subscribe({
      next: (result) => {},
      error: (err) => {
        this.showErrorMessage('Failed to copy configuration file');
      }
    });
  }

  private configurationFileRequest(data: any, req: any): any {
    return {
      currentConfigId: data.configId,
      previousConfigId: this.prevConfigId,
    };
  }

  private prepareCashFlowRequest(data: any, req: any): any {
    return {
      configId: data.configId,
      prevConfigId: this.prevConfigId,
      fundId: req.fundGroupName ? null : data.fundId,
      fundIdList: req?.fundGroupName ?  req?.fundIdList : null,
      endDate: moment(data.endDate, 'YYYY-MM-DD').format('YYYY-MM-DD'),
      enterpriseId: data.enterpriseId,
      startDate: moment(data.startDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
    };
  }

  private prepareFsExposureRequest(data: any, req: any): any {
    return {
      configId: data.configId,
      prevConfigId: this.prevConfigId,
      fundId: req.fundGroupName ? null : data.fundId,
      fundIdList: req?.fundGroupName ?  req?.fundIdList : null,
      endDate: moment(data.endDate, 'YYYY-MM-DD').format('YYYY-MM-DD'),
      startDate: moment(data.startDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
    };
  }

  private prepareFSPositionRequest(data: any, req: any): any {
    return {
      configId: data.configId,
      prevConfigId: this.prevConfigId,
      fundId: req?.fundGroupName ? null : data.fundId,
      fundIdList: req?.fundGroupName ?  req?.fundIdList : null,
      fundGroupName: req?.fundGroupName ? req?.fundGroupName : null,
      endDate: moment(data.endDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
    };
  }


  private prepareChartOfAccountsRequest(data: any, req: any): any {
    return {
      configId: data.configId,
      prevConfigId: this.prevConfigId,
      fundId: req.fundGroupName ? null : data.fundId,
      endDate: moment(data.endDate, 'YYYY-MM-DD').format('YYYY-MM-DD'),
    };
  }

  private prepareFScashAndCashEquivalentRequest(data: any, req: any): any {
    return {
      configId: data.configId,
      prevConfigId: this.prevConfigId,
      fundId: req?.fundGroupName ? null : data.fundId,
      fundIdList: req?.fundGroupName ?  req?.fundIdList : null,
      date: moment(data.endDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
    };
  }

  private showErrorMessage(message: string): void {
    this.messageService.add({
      closable: true, sticky: true, severity: 'error',
      summary: this.translate.instant('error'),
      detail: this.translate.instant(message)
    });
    this.formValid = false;
  }

  private onSuccessfulCreation(): void {
    this.dialogRef.close(true);
    this.messageService.add({
      closable: true, sticky: true, severity: 'success',
      summary: this.translate.instant('success'),
      detail: this.translate.instant('record_created')
    });
  }
  fetchAllByEnterprise(): Observable<any> {
    return this.http.get<any>('fundV2/fetchAllByEnterprise')
  }

  setscheduleConfig(data) {
    return this.http.post<any>('scheduleConfig/create', data);
  }

  cashFlowUpdate(reqObj): Observable<any> {
    return this.http.get<any>(`cashFlowUpdate/records/0/${reqObj.fundId}/${reqObj.configId}/${reqObj.enterpriseId}/${reqObj.startDate}/${reqObj.endDate}/true/${reqObj.prevConfigId}`)
  }

  copyConfigurationFile(data) {
    return this.http.post<any>('fSConfig/copyConfigurationFile', data);
  }


}
