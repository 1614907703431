
/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/ 
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { apiConstants } from '../../../../../src/app/constants/apiConstants';
import { GlobalMessageService } from 'src/app/shared/services/commonServices/global-message.service';
import moment from 'moment-timezone';
import { ProcessScreenService } from '../../services/processService/process-screen.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../../src/environments/environment';
import { LocalStoreService } from '../../services/commonServices/local-store.service';
import { CommonService } from '../../services/commonServices/common.service';

@Component({
  selector: 'fury-refresh-button',
  templateUrl: './refresh-button.component.html',
  styleUrls: ['./refresh-button.component.scss']
})
export class RefreshButton {
  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    })
  };

  public params: any;
  alteryxWorkflowStatus: any;
  public omsCancel: boolean;
  public omsApproveList: boolean;
  public omsEditList: boolean;
  public moduleName: string;
  public wrkFlwSts:any;
  executionId: any;
  public executionStatus:any;
  isProcessing: boolean;
  currentUser: any;
  public isdViewBtnCheck: boolean;
  public isdEnableViewBtnCheck:boolean;
  public isdGroupViewBtnCheck:boolean;
  isAdhocDistributionViewBtnCheck: boolean;
  public ticker: any;
  public primaryAssetClassName: any;

  constructor(private http: HttpClient, private messageService: GlobalMessageService,
    private processScreenService: ProcessScreenService,private translateService: TranslateService,
    private localService: LocalStoreService, private commonService : CommonService) { }
  public status: any;

  agInit(params: ICellRendererAngularComp): void {
    this.currentUser = JSON.parse(this.localService.getItem('fss'));
    this.isdViewBtnCheck = this.currentUser.authList.indexOf('SEAMLESS_ISD_INVESTOR_STATEMENT_DISTRIBUTION_VIEW') > -1 ? true : false;
    this.isdEnableViewBtnCheck = this.currentUser.authList.indexOf('SEAMLESS_ISD_REDISTRIBUTION_VIEW') > -1 ? true : false;
    this.isdGroupViewBtnCheck =this.currentUser.authList.indexOf('SEAMLESS_ISD_GROUP_APPROVAL_FUND_VIEW') > -1 ? true : false;
    this.isAdhocDistributionViewBtnCheck=this.currentUser.authList.indexOf('SEAMLESS_ISD_ADHOC_DISTRIBUTION_ACTION') > -1 ? true : false;

    this.params = params;
    this.status = this.params.data.status;
    this.alteryxWorkflowStatus = this.params.data.alteryxWorkflowStatus;
    this.ticker = this.params.data?.ticker;
    this.primaryAssetClassName = this.params.data?.primaryAssetClassName;


    if (this.params.api?.componentName) {
      this.moduleName = apiConstants[this.params.api.componentName] != (undefined || null) ? apiConstants[this.params.api.componentName]['moduleName'] : this.params.api.componentName;
    }
    if(this.moduleName == 'workflowStatus'){
      this.executionStatus = this.params.data.executionStatus;
    }
    if(this.moduleName && this.moduleName != 'executeWorkflow') {
        if (this.params.data && !this.params.data.status) {
          this.executionId = "";
          this.wrkFlwSts = "";
        }else{
          this.executionId = this.params.data.executionId ? this.params.data.executionId : "" ;

          this.wrkFlwSts = this.params.data.status ? this.params.data.status : "" ;
          this.wrkFlwSts = this.wrkFlwSts.toLowerCase();
        }
   }
   
   if(this.moduleName && this.moduleName == 'executeWorkflow'){
      if (this.params.data && !this.params.data.alteryxWorkflowStatus) {
          this.executionId = "";    
          this.wrkFlwSts = "";
      } else {
        this.executionId = this.params.data.alteryxWorkflowStatus.executionId ? this.params.data.alteryxWorkflowStatus.executionId : "" ;

        this.wrkFlwSts = this.params.data.alteryxWorkflowStatus.status ? this.params.data.alteryxWorkflowStatus.status : "" ;
        this.wrkFlwSts = this.wrkFlwSts.toLowerCase();
      }
   }
    //this.wrkFlwSts = 'completed'  
    //this.wrkFlwSts = 'queued'   
  }
  updateWorkflowStatusUser(executionId, event) {
    if(this.moduleName == "workflowStatus"){
      this.isProcessing = true;
    }else{
      this.isProcessing = false;
    }
     var rowsData = [];
    if(executionId){
    let rowId = this.params.node.rowIndex != 0 ? this.params.node.rowIndex : 0;
    var rowNode = this.params.api.getRowNode(rowId);
    this.http.get<any>('configurationsV2/getWorkflowStatus?executionId=' + executionId).subscribe(response => {
      if (response) { 
        rowsData = response
        if(rowNode){
           this.wrkFlwSts =  rowsData['status'];
          // rowsData.forEach(element =>{
           if(this.moduleName == "dataImportStatusManager" || this.moduleName == "dataImportStatus"
          || this.moduleName == "workflowStatus" || this.moduleName == "executeWorkflow") {

              if (rowsData['runDate']) {
                  rowsData["runDate"] = moment(new Date(rowsData['runDate'])).format("MM/DD/YYYY hh:mm:ss A");                  
              } 

              if (rowsData['createdDate']) {
                let createdDate = rowsData['createdDate'].toString() + "z";
                rowsData["createdDate"] = moment(new Date(createdDate)).format("MM/DD/YYYY hh:mm:ss A");
              } else {
                rowsData["createdDateStr"] = null;
              }

              if (rowsData['endDate']) {
                let endDate =rowsData['endDate'].toString() + "z";
                rowsData["endDate"] = moment(new Date(endDate)).format("MM/DD/YYYY hh:mm:ss A");
              } else {
                rowsData["endDateStr"] = null;
              }
            }
            // Change execution date and time format for data import status
            if(this.moduleName == "workflowStatus"){
              if (rowsData['createdDate']) {
                rowsData["createdDate"] = moment(new Date(rowsData['createdDate'])).format("MM/DD/YYYY HH:mm");
              } else {
                rowsData["createdDateStr"] = null;
              }
              if(rowsData['autoDownloadFile']){
                var uniqueId;
                let type:string = 'Zip file';
                  if (rowsData['uniqueNumber']) {
                    uniqueId = rowsData['uniqueNumber'];
                    let url = environment.api_url + 'alteryxExecuteWorkflow/exportFile/' + uniqueId +'/' +type;
  	                // this.processScreenService.downloadCSV(url, 'Failed Data.xlsx','Failed Data');
                    this.commonService.GenericDownload(url,'Failed Data','Failed Data')

                  } else {
                    this.messageService.add({ sticky: true, severity: 'error', summary: this.translateService.instant('invalid_data'), detail: this.translateService.instant('error') });
                    return;
                  }
               /* if(rowsData['autoDownloadFile'] == "preview"){
                  let url = environment.api_url + 'alteryxExecuteWorkflow/getTempData/' + uniqueId;
                  this.processScreenService.downloadCSV(url, 'Preview Data.csv','Preview Data');  
                }else if(rowsData['autoDownloadFile'] == "failed data"){
                  let url = environment.api_url + 'alteryxExecuteWorkflow/exportFailedData/' + uniqueId;
                  this.processScreenService.downloadCSV(url, 'Failed Data.xlsx','Failed Data');
                }else if(rowsData['autoDownloadFile'] == "preview and failed data"){
                  //preview data
                  let url = environment.api_url + 'alteryxExecuteWorkflow/getTempData/' + uniqueId;
                  this.processScreenService.downloadCSV(url, 'Preview Data.csv','Preview Data');  
                  // failed data
                  let urlFailed = environment.api_url + 'alteryxExecuteWorkflow/exportFailedData/' + uniqueId;
                  this.processScreenService.downloadCSV(urlFailed, 'Failed Data.xlsx','Failed Data');
                }*/
              }
            }
           // })
           rowNode.setData(rowsData);
        }
      }
      else {
         this.messageService.add({sticky:true,severity: 'error', summary: 'Error', detail: 'error occurred!',closable: true });
      }
    })
  }
  }

  viewrowDataCompliance(event) {
    let selectedNode = this.params.node;
    let selectedData = selectedNode.data;
    if (this.params.viewrowDataCompliance instanceof Function) {
      const params = {
        event: event,
        rowData: selectedData
      }
      this.params.viewrowDataCompliance(params);
    }
  }
  getIsdReportResponseDTO(event) {
    let selectedNode = this.params.node;
    let selectedData = selectedNode.data;
    if (this.params.viewrowDataCompliance instanceof Function) {
      const params = {
        event: event,
        rowData: selectedData
      }
      this.params.viewrowDataCompliance(params);
    }
  }


  getIsdEnableInvestorReDistributionDTO(event) {
    let selectedNode = this.params.node;
    let selectedData = selectedNode.data;
    if (this.params.viewrowDataCompliance instanceof Function) {
      const params = {
        event: event,
        rowData: selectedData
      }
      this.params.viewrowDataCompliance(params);
    }
  }

  getIsdConsolidateGridReportData(event,info) {
    let selectedNode = this.params.node;
    let selectedData = selectedNode.data;
    if (this.params.viewrowDataCompliance instanceof Function) {
      const params = {
        event: info,
        rowData: selectedData
      }
      this.params.viewrowDataCompliance(params );
    }
  }

}
