<div *ngIf="data.isEditing && !columnDef.isDisabled">
    <div *ngIf="!isAddingNewItem">
      <p-dropdown class="editableSearchDropdown" [options]="filteredList" (onChange)="onSelect()"
        [style]="{ height: '28px' }" [(ngModel)]="selectedValue" optionLabel="label" optionValue="value" [filter]="true"
        filterBy="label" [showClear]="false" placeholder="Select" appendTo="body">
      </p-dropdown>
      <span (click)="$event.stopPropagation(); addDataItem();" class="material-icons-outlined addSelectOptionTax">
        add_circle_outline
      </span>
    </div>
    <div *ngIf="isAddingNewItem" class="add-new-item-container">
      <input type="text" [(ngModel)]="newItem" placeholder="State" class="new-item-input addTax">
      <span (click)="$event.stopPropagation(); saveDataItem();" class="material-icons-outlined addSelectOptionTax">
        check_circle_outline
      </span>
      <span (click)="$event.stopPropagation(); cancelDataItem();" class="material-icons-outlined addSelectOptionTax1">
        highlight_off
      </span>
    </div>
  </div>
  <span *ngIf="!data.isEditing || columnDef.isDisabled">{{value}}</span>