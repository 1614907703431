/*******************************************************************************
* FORMIDIUM Corp. COPYRIGHT STATEMENT
*  __________________
*
*  2022 - 2023 FORMIDIUM Corp.
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of FORMIDIUM Corp.
* The intellectual and technical concepts contained
* herein are proprietary to FORMIDIUM Corp.
* and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret and/or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from FORMIDIUM Corp.. Contact information for FORMIDIUM Corp. Ltd may be obtained
* by visiting www.formidium.com.
******************************************************************************/

import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { SidebarDirective } from '../../../../../src/@fury/shared/sidebar/sidebar.directive';
import { AuthService } from '../../../../../src/app/shared/services/commonServices/auth.service';
import { CommonService } from '../../../../../src/app/shared/services/commonServices/common.service';
import { GlobalMessageService } from 'src/app/shared/services/commonServices/global-message.service';
import { EventEmitter } from 'stream';
import { LayoutService } from '../../layout.service';
import { MessageService } from 'primeng/api';
import { LocalStoreService } from 'src/app/shared/services/commonServices/local-store.service';
@Component({
  selector: 'fury-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  styleUrls: ['./toolbar-user.component.scss']
})
export class ToolbarUserComponent implements OnInit {

  isOpen: boolean;
  userName: String;
  imgUrl: String;
  displayBasic: boolean = false;
  displaychangePassword: boolean = false;
  showPasswordMatchText: boolean = false;
  clearbuttonShow: boolean = false;
  public uploadedData: any
  dragDrop: boolean = true;
  filename: any;
  subscription: Subscription;
  changePasswordForm: FormGroup;
  passwordStrength1: boolean = false;
  passwordStrength: boolean = false;
  passwordStrength2: boolean = false;
  passwordStrength3: boolean = false;
  passwordStrength4: boolean = false;
  passwordStrength5: boolean = false;
  passwordStrength6: boolean = false;
  public formValid: boolean = true;

  public fileUpload: any;
  @ViewChild('configPanel', { static: true }) configPanel: SidebarDirective;
  languagetype: any;
  newfileName: string;
  file: any;
  uploadedFiles: any[] = [];
  public form: any;
  currentUser: any;
  pinedMenu: any;
  username: string;
  Id: any;
  visible = false;
  visibles = false;
  visibless = false;
  inputType = 'password';
  inputTypes = 'password';
  inputTypess = 'password';
  constructor(private auth: AuthService, private layoutService: LayoutService,
    private router: Router,
    private fb: FormBuilder,
    public translate: TranslateService,
    private commonService: CommonService,
    private http: HttpClient,
    private messageService: GlobalMessageService,
    private messageService1: MessageService, private changeDetectorRef: ChangeDetectorRef, private localService: LocalStoreService
  ) {
    this.userName = JSON.parse(this.localService.getItem('fss'))['userName'].split('@')[0]
    // this.userName = JSON.parse(this.localService.getItem('fss'))['userName'].split('@')[0];
    this.currentUser = JSON.parse(this.localService.getItem('fss'));
    this.imgUrl = this.currentUser['photoUrl'];
    this.changePasswordForm = this.fb.group({
      currentPassword: ['', Validators.compose([Validators.required])],
      newPassword: ['', [Validators.required, this.checkpaswd]],
      confirmPassword: ['', Validators.required]
    },
      { validator: this.MustMatch('newPassword', 'confirmPassword') })
  }
  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      let control = formGroup.controls[controlName];
      let matchingControl = formGroup.controls[matchingControlName]
      if (control.value === matchingControl.value && control.value != null && matchingControl.value != null) {
        if (this.passwordStrength && this.passwordStrength1 && this.passwordStrength2 && this.passwordStrength3 && this.passwordStrength4 && this.passwordStrength5 && this.passwordStrength6) {
          this.formValid = false;
          matchingControl.setErrors(null);
          return;
        }
      }
      else {
        this.formValid = true;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ MustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
  ngOnInit() {
    this.currentUser = JSON.parse(this.localService.getItem('fss'));
    this.username = JSON.parse(this.localService.getItem('fss'))['userName'];
  }
  get f() {
    return this.changePasswordForm.controls;
  }
  openConfigPanel() {
    this.configPanel.open();
  }
  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  onClickOutside() {
    this.isOpen = false;
  }

  myUploader(event, form) {
    this.form = form;
    this.filename = event.files[0];
    this.filename.name ? this.clearbuttonShow = true : this.clearbuttonShow = false;
    this.filename.name ? this.dragDrop = false : this.dragDrop = true;

  }
  clear() {
    this.form.clear();
    this.uploadedData = null;
    this.clearbuttonShow = false;
    this.dragDrop = true;
  }
  signout() {
    this.resetHubspotForm()
    this.languagetype = JSON.parse(this.localService.getItem('fss'))['switchLanguageEng']
    this.auth.logout().subscribe(data => {
      this.auth.signout();
      this.messageService1.clear();
      this.getFormData()
    }, (error) => {
      console.error(error);
      this.auth.signout();
      this.messageService1.clear();
    });
  }

  resetHubspotForm() {
    const fields = [
      'firstname-acae2095-9d1a-43bf-b4f9-8b1baf62d020',
      'lastname-acae2095-9d1a-43bf-b4f9-8b1baf62d020',
      'user_name-acae2095-9d1a-43bf-b4f9-8b1baf62d020',
      'email-acae2095-9d1a-43bf-b4f9-8b1baf62d020',
      'problem_overview-acae2095-9d1a-43bf-b4f9-8b1baf62d020',
      'description-acae2095-9d1a-43bf-b4f9-8b1baf62d020',
      'attach_files__optional_-acae2095-9d1a-43bf-b4f9-8b1baf62d020'
  ];  
  fields.forEach(id => {
      const element = document.getElementById(id) as HTMLTextAreaElement;
      if (element) {
          element.value = "";
      }
  });
  }

  ListData() {
    this.commonService.userPerferenceData(this.username).subscribe((res: any) => {
      let res1 = res.languageSettings;
      if (res1 && (res1.length > 0)) {
        this.pinedMenu = res1;
        this.languagetype = res1[0].settingName == null ? JSON.parse(JSON.parse(this.localService.getItem('fss'))['switchLanguageEng']) : res1[0].settingName;
        if (this.pinedMenu && this.pinedMenu.length) {
          var reqBody = {
            "id": this.pinedMenu[0].id,
            "userName": this.username,
            "view": 'languageSettings',
            "settingName": this.languagetype,
            "setting": {
              "pivotMode": 'languageSettings',
            },
            status: true,
          }

          this.http.put('userPerferenceV2/update', reqBody).subscribe(res2 => {
            if (res2) {
              this.signout();
            }
          })
        }
        else {
          let reqBody = {
            "userName": this.username,
            "view": 'languageSettings',
            "settingName": this.languagetype,
            "setting": {
              "pivotMode": 'languageSettings',
            },
            status: true,
          }

          this.http.post('userPerferenceV2/create', reqBody).subscribe(items => {
            this.Id = items['id']
            if (this.Id != null) {
              this.signout();
            }

          })
        }
      }

      else {
        this.signout();
      }

    })
    this.getFormData()
  }
  openProfileDialog() {
    this.displayBasic = true;
  }
  openchangePassword() {
    this.displaychangePassword = true;
  }
  hideDialogBox() {
    this.displayBasic = false;
  }
  hidechangeDialogBox() {
    this.displaychangePassword = false;
  }
  uploadProfile() {

    var fileDetails = {
      fileName: this.filename.name,
      fieldName: 'undefined',
      moduleName: 'undefined'
    }
    this.newfileName = this.changeFileName(fileDetails);
    const formData = new FormData();
    formData.append("file", this.filename, this.newfileName);
    this.layoutService.fileUpload(formData).subscribe((res) => {
      this.imgUrl = res.photoUrl;
      this.sendMessage(this.imgUrl);
      let val = JSON.parse(this.localService.getItem('fss'));
      if (val) {
        val['photoUrl'] = res.photoUrl
        this.localService.setItem('fss', JSON.stringify(val));
      }

      this.messageService.add({ sticky: true, severity: 'success', summary: this.translate.instant('success'), detail: this.translate.instant('file_uploaded_successfully') });

      this.displayBasic = false;
      this.clear();
    }, (error) => {

      this.messageService.add({ sticky: true, severity: 'error', summary: this.translate.instant('error!'), detail: this.translate.instant('file_upload_failed!') });

    })

  }
  ngAfterViewChecked() {
    this.checkpaswd;
    this.changeDetectorRef.detectChanges()
  }

  checkpaswd: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    let flag;
    let value: string = this?.changePasswordForm?.get('newPassword')?.value || '';
    if (!value) {
      return null;
    }

    let upperCaseCharacters = /[A-Z]+/g;
    let lowerCaseCharacters = /[a-z]+/g;
    let numberCharacters = /[0-9]+/g;
    let specialCharacters = /[~!@#$^<>,.]+/;
    let eightChar = /.{8,}/;
    let spaceChar = /^\S{8,}$/;
    upperCaseCharacters = /[A-Z]+/g;
    if (upperCaseCharacters.test(value) === true) {
      this.passwordStrength = true
    } else {
      this.passwordStrength = false
    }

    lowerCaseCharacters = /[a-z]+/g;
    if (lowerCaseCharacters.test(value) === true) {
      this.passwordStrength1 = true
    } else {
      this.passwordStrength1 = false;
    }

    numberCharacters = /[0-9]+/g;
    if (numberCharacters.test(value) === true) {
      this.passwordStrength2 = true
    } else {
      this.passwordStrength2 = false;
    }

    //Ony characters /^[a-zA-Z0-9~!@#$^<>,.]+$/. excluding *, ""
    var OnlySpecial = /^[a-zA-Z0-9~!@#$^<>,.]+$/;
    if (OnlySpecial.test(value)) {
      this.passwordStrength5 = true;
    } else {
      this.passwordStrength5 = false;
    }

    if (eightChar.test(value) === true) {
      this.passwordStrength4 = true
    } else {
      this.passwordStrength4 = false
    }

    if (spaceChar.test(value) === true) {
      this.passwordStrength6 = true
    } else {
      this.passwordStrength6 = false
    }

    specialCharacters = /[~!@#$^<>,.]+/;
    if (specialCharacters.test(value) === true) {
      this.passwordStrength3 = true
    } else {
      this.passwordStrength3 = false
    }
  }


  changePassword() {
    //this.changePasswordForm.controls.confirmPassword.value
    if (this.changePasswordForm.controls.currentPassword.value == '' || this.changePasswordForm.controls.newPassword.value == '' || this.changePasswordForm.controls.confirmPassword.value == '') {
      return true;
    } else if (this.changePasswordForm.controls.newPassword.value != this.changePasswordForm.controls.confirmPassword.value) {
      return true;
    } else if (this.passwordStrength2 == false || this.passwordStrength3 == false || this.passwordStrength1 == false ||
      this.passwordStrength4 == false || this.passwordStrength == false || this.passwordStrength5 == false || this.passwordStrength6 == false) {
      alert("Please enter Strong Password");
      return true;
    }
    var authPasswords = {
      newPassword: this.changePasswordForm.controls.newPassword.value,
      currentPassword: this.changePasswordForm.controls.currentPassword.value,
      userId: this.currentUser['id']
    };
    delete (this.changePasswordForm['confirmPassword']);
    let formobj = btoa(JSON.stringify(authPasswords));
    this.layoutService.changePassword(formobj, (cbs) => {
      if (cbs == true) {
        this.messageService.add({ sticky: true, severity: 'success', summary: this.translate.instant('success'), detail: this.translate.instant('password_change_successfully') });
        this.displaychangePassword = false;
        this.changePasswordForm.reset();
      } else if (cbs == false) {
        this.messageService.add({ sticky: true, severity: 'error', summary: this.translate.instant('error'), detail: this.translate.instant('you_entered_wrong_password') });
        this.displaychangePassword = false;
        this.changePasswordForm.reset();
      } else {
        this.messageService.add({ sticky: true, severity: 'error', summary: this.translate.instant('error'), detail: this.translate.instant(cbs) });
        this.displaychangePassword = false;
        this.changePasswordForm.reset();
      }
      this.clear();
    }, (error) => {
      this.messageService.add({ sticky: true, severity: 'error', summary: this.translate.instant('error'), detail: this.translate.instant(error.error.text) });
    })
  }

  onChangeEvent(event, field) {
    if (event.inputType == 'insertFromPaste') {
      if (field) {
        let res = this.changePasswordForm.get(field).value
        if (res) {
          res = res.replace(/\s/g, "")
          this.changePasswordForm.get(field).patchValue(res)
        }
      }
    }

    let changePasswordForm = this.changePasswordForm.value;
    if (changePasswordForm.newPassword == '') {
      this.passwordStrength = false;
      this.passwordStrength1 = false;
      this.passwordStrength2 = false;
      this.passwordStrength3 = false;
      this.passwordStrength4 = false;
      this.passwordStrength5 = false;
      this.passwordStrength6 = false;
    } else if (changePasswordForm.newPassword != "" && changePasswordForm.newPassword.length < 8) {
      this.passwordStrength4 = false;
    }
  }

  changeFileName(obj) {
    var fileNameArr = obj.fileName.split('.');
    var actualFileName = fileNameArr.slice(0, fileNameArr.length - 1).join('.');
    var separator = '##';
    return actualFileName + separator + obj.fieldName + separator + obj.moduleName + "." + fileNameArr[fileNameArr.length - 1];
  }

  sendMessage(imgUrl) {
    this.layoutService.sendMessage(imgUrl);
  }

  openProcessLog() {
    this.router.navigateByUrl('process-log');
    this.isOpen = false;
  }

  processbar() {
    this.router.navigateByUrl('process-bar');
    this.isOpen = false;
  }

  openAuditRequest() {
    this.router.navigateByUrl('audit-request');
    this.isOpen = false;
  }

  openProcessLogArchive() {
    this.router.navigateByUrl('process-log-archive');
    this.isOpen = false;
  }

  toggleVisiblity(type) {
    if (type == 1) {
      this.visible = !this.visible
      this.inputType = this.visible ? 'text' : 'password';
    }
    if (type == 2) {
      this.visibles = !this.visibles
      this.inputTypes = this.visibles ? 'text' : 'password';
    }
    if (type == 3) {
      this.visibless = !this.visibless
      this.inputTypess = this.visibless ? 'text' : 'password';
    }
  }

  getFormData() {
    if (this.currentUser) {
      document.getElementsByClassName('hotspottalk')[0]['style'].display = 'none'
    } else { document.getElementsByClassName('hotspottalk')[0]['style'].display = 'block' }
  }

  chooseMfaType() {
    this.router.navigateByUrl('chooseSetupMfaType');
    this.isOpen = false;
  }
}
