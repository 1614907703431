import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, BehaviorSubject, switchMap, catchError, of } from "rxjs";

@Injectable()
export class FsScheduleCardsService {
  public httpOptions = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    })
  };

  public isApiCalled: boolean = false;

  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  private gridStateSubject = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) { }

  updateGridState(state: boolean) {
    this.gridStateSubject.next(state);
  }

  getGridState() {
    return this.gridStateSubject.asObservable();
  }

  private postData(url: string, data: any): Observable<any> {
    this.loadingSubject.next(true);
    return this.http.post<any>(url, data, this.httpOptions).pipe(
      catchError((error) => {
        this.loadingSubject.next(false);
        throw error;
      })
    );
  }

  refreshFSInvestorList(data): Observable<any> {
    return this.postData('fSInvestor/create', data);
  }

  refreshFSPortfolioList(data): Observable<any> {
    return this.postData('fSPortfolio/create', data);
  }

  refreshFsChartOfAccount(data): Observable<any> {
    return this.postData('fsChartOfAccounts/create', data);
  }

  copyChartOfAccountsConfig(data): Observable<any> {
    return this.postData('fsChartOfAccounts/copyChartOfAccountsConfig', data);
  }

  copyFSPositionConfig(data): Observable<any> {
    return this.postData('fSPortfolio/copyFSPositionConfig', data);
  }

  refreshFsCashAndCashEquivalent(data): Observable<any> {
    return this.postData('cashAndCashEquivalent/create', data);
  }

  refreshFsExposure(data): Observable<any> {
    return this.postData('fsExposure/create', data);
  }


  refreshDataSequentially(fsChartOfAccountData: object,
    fsPortfolioData: object,
    //fsInvestorData: object
  ): Observable<any> {
    this.loadingSubject.next(true);
    return this.refreshFsChartOfAccount(fsChartOfAccountData).pipe(
      switchMap(() => this.refreshFSPortfolioList(fsPortfolioData)),
      // switchMap(() => this.refreshFSInvestorList(fsInvestorData)),
      catchError((error) => {
        this.loadingSubject.next(false);
        throw error;
      }),
      switchMap(() => {
        this.loadingSubject.next(false);
        return of('Refresh complete');
      })
    );
  }
}
